@import "~@/assets/scss/global.scss";








































































































































































::v-deep {
    .el-aside {
        .el-textarea {
            height: 100%;
        }

        .el-textarea__inner {
            height: 100%;
        }
    }

    .el-main {
        border-top: 0px !important;
        .stall-trade-table {
            height: 100%;
        }
    }
}
